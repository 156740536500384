
export default {
	init() { 
        // Set forgot password text to default password reset link
        const forgotPasswordText = document.querySelector('.left-side').dataset.forgotPasswordText;
        const resetPasswordButton = document.querySelector('.widget_aam_backend_login > p > a ');
        resetPasswordButton.innerText = forgotPasswordText;
    },
	finalize() {},
};
