export default {
	init() {},
	finalize() {
		// JavaScript to be fired on the home page, after the init JS

		window.addEventListener("resize", (e) => {
			setTimeout(() => {
				accordionWidth();
			}, 200);
		});

		let desktop = window.matchMedia("(min-width: 1200px)");
		let laptop = window.matchMedia("(min-width: 992px)");
		let tablet = window.matchMedia("(min-width: 768px)");

		const accordionWidth = () => {
			// calculate width of accordion boxes and assign to css variable for proper ellipses usage
			const accordion = document.querySelector(".accordion.nested");
			if (accordion) {
				if (desktop.matches) {
					accordion.style.setProperty(
						"--accordion-content-width",
						`${accordion.offsetWidth / 2 - 80}px`
					);
					accordion.style.setProperty(
						"--accordion-header-content-width",
						`${accordion.offsetWidth / 2 - 140}px`
					);
				} else if (laptop.matches) {
					accordion.style.setProperty(
						"--accordion-content-width",
						`${accordion.offsetWidth / 2 - 80}px`
					);
					accordion.style.setProperty(
						"--accordion-header-content-width",
						`${accordion.offsetWidth / 2 - 100}px`
					);
				} else if (tablet.matches) {
					accordion.style.setProperty(
						"--accordion-content-width",
						`${accordion.offsetWidth / 2 - 40}px`
					);
					accordion.style.setProperty(
						"--accordion-header-content-width",
						`${accordion.offsetWidth / 2 - 75}px`
					);
				} else {
					accordion.style.setProperty(
						"--accordion-content-width",
						`${accordion.offsetWidth - 85}px`
					);
					accordion.style.setProperty(
						"--accordion-header-content-width",
						`${accordion.offsetWidth - 100}px`
					);
				}
			}
		};
		accordionWidth();

		const accordionButtons = () => {
			const moduleButtons = document.querySelectorAll(
				".module .module-header button.title, .module .module-header button.date, .folder-content-header button.title, .folder-content-header button.date"
			);

			// for a11y, could not wrap both elems in button
			// need both to hover at same time from visual perspective
			// the buttons do the same thing, so this adds a hover class to both on hover
			moduleButtons.forEach((moduleButton) => {
				const addHoveredClass = (e) => {
					const currentButton = e.target;
					currentButton.classList.add("hovered");
					// if title is hovered, find next date elem and hover
					if (currentButton.classList.contains("title")) {
						const nextDate =
							currentButton.parentElement.nextElementSibling;
						nextDate.classList.add("hovered");
					} else {
						// if date is hovered, find prev title elem and hover
						const prevTitle =
							currentButton.previousElementSibling
								.firstElementChild;
						prevTitle.classList.add("hovered");
					}
				};

				const removeHoveredClass = (e) => {
					const currentButton = e.target;
					currentButton.classList.remove("hovered");
					// if title is hovered, find next date elem and remove hover
					if (currentButton.classList.contains("title")) {
						const nextDate =
							currentButton.parentElement.nextElementSibling;
						nextDate.classList.remove("hovered");
					} else {
						// if date is hovered, find prev title elem and remove hover
						const prevTitle =
							currentButton.previousElementSibling
								.firstElementChild;
						prevTitle.classList.remove("hovered");
					}
				};

				const addFocusedClass = (e) => {
					const currentButton = e.target;
					currentButton.classList.add("focused");
					// if title is hovered, find next date elem and focus
					if (currentButton.classList.contains("title")) {
						const nextDate =
							currentButton.parentElement.nextElementSibling;
						nextDate.classList.add("focused");
					} else {
						// if date is hovered, find prev title elem and focus
						const prevTitle =
							currentButton.previousElementSibling
								.firstElementChild;
						prevTitle.classList.add("focused");
					}
				};

				const removeFocusedClass = (e) => {
					const currentButton = e.target;
					currentButton.classList.remove("focused");
					// if title is hovered, find next date elem and remove focus
					if (currentButton.classList.contains("title")) {
						const nextDate =
							currentButton.parentElement.nextElementSibling;
						nextDate.classList.remove("focused");
					} else {
						// if date is hovered, find prev title elem and remove focus
						const prevTitle =
							currentButton.previousElementSibling
								.firstElementChild;
						prevTitle.classList.remove("focused");
					}
				};

				moduleButton.addEventListener("mouseenter", addHoveredClass);
				moduleButton.addEventListener("mouseleave", removeHoveredClass);
				// wrap both buttons in focus rings on tab
				moduleButton.addEventListener("focus", addFocusedClass);
				moduleButton.addEventListener("blur", removeFocusedClass);
			});
		};
		accordionButtons();
	},
};
