const mobileMenuFunctions = () => {
	// media query for mobile
	let mobile = window.matchMedia("(max-width: 767px)");

	const menuToggle = document.getElementById("menu-toggle");
	const html = document.querySelector("html");
	const menu = document.getElementById("mobile-menu");
	const logoLink = document.getElementById("header-logo").querySelector("a");
	let menuLinks = []
	let lastFocusableLink = menuLinks[menuLinks.length - 1];
	
	if (menu){
		menuLinks = menu.querySelectorAll(
			"a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type='text']:not([disabled]), input[type='radio']:not([disabled]), input[type='checkbox']:not([disabled]), input[type='submit']:not([disabled]), select:not([disabled])"
		);
		lastFocusableLink = menuLinks[menuLinks.length - 1];
		window.addEventListener("load", () => {
			mobileMenu();
		});
	
		window.addEventListener("resize", () => {
			setTimeout(() => {
				mobileMenu();
			}, 200);
		});
	
	}

	const menuToggleState = (e) => {
		e.preventDefault();
		menu.classList.toggle("menu-open");
		menuToggle.classList.toggle("menu-open");
		html.classList.toggle("no-scroll");
		// remove menu links' ability to focus if menu is not open
		// menuLinks.forEach((link) => {
		// 	if (menu.classList.contains("menu-open")) {
		// 		link.removeAttribute("tabindex");
		// 	} else {
		// 		// if menu is not open, ignore the links
		// 		link.setAttribute("tabindex", "-1");
		// 	}
		// });
		// trap focus when menu is open
		lastFocusableLink.addEventListener("keydown", (e) => {
			// if focused on last link and going backwards, do nothing
			if (e.shiftKey && e.keyCode === 9) {
				return null;
			} else if (e.keyCode === 9) {
				// if going forwards, focus on logo
				e.preventDefault();
				logoLink.focus();
			}
		});
		logoLink.addEventListener("keydown", (e) => {
			// check if menu is open
			if (menu.classList.contains("menu-open")) {
				e.preventDefault();
				// if focused on logo and going backwards, go to last menu link
				if (e.shiftKey && e.keyCode === 9) {
					lastFocusableLink.focus();
				} else if (e.keyCode === 9) {
					// if going forwards, go to menu toggle
					menuToggle.focus();
				} else if (e.keyCode === 13) {
					// if user hits enter on logo, remove menu state & go to homepage
					menu.classList.contains("menu-open");
					window.location.href = window.location.origin;
				}
			} else {
				// if menu is not open, proceed as normal
				if (e.shiftKey && e.keyCode === 9) {
					return null;
				}
			}
		});
	};

	const closeMenu = () => {
		menu.classList.remove("menu-open");
		menuToggle.classList.remove("menu-open");
		html.classList.remove("no-scroll");
		// menuLinks.forEach((link) => {
		// 	// set menu to ignore links for when user goes back to mobile
		// 	link.setAttribute("tabindex", "-1");
		// });
	};

	const mobileMenu = () => {
		if (mobile.matches) {
			// set height for open menu
			document.documentElement.style.setProperty(
				"--window-inner-height",
				`${window.innerHeight}px`
			);
			menuToggle.addEventListener("click", menuToggleState);
		} else {
			document.documentElement.style.removeProperty(
				"--window-inner-height"
			);
			// if user resizes and mobile menu is open, close it
			closeMenu();
		}
	};
};
mobileMenuFunctions();
