import Combobo from "combobo";

export default {
	init() {},
	finalize() {
		// JavaScript to be fired on the home page, after the init JS
		let laptopLg = window.matchMedia("(min-width: 1050px)");

		window.addEventListener("resize", (e) => {
			setTimeout(() => {
				sortSearchResponsive();
			}, 200);
		});

		const sortSearchResponsive = () => {
			const searchSort = document.querySelector(".search-sort .wrapper");
			if (searchSort) {
				const search = searchSort.querySelector(".search-wrap");
				const sort = searchSort.querySelector(".sort-by");
				const searchPlaceholder =
					searchSort.querySelector(".placeholder-text").innerText;
				if (laptopLg.matches) {
					// parent element.insertBefore(element you want second, element you want first)
					searchSort.insertBefore(sort, search);
					search.querySelector("input").placeholder =
						searchPlaceholder;
				} else {
					// parent element.insertBefore(element you want second, element you want first)
					searchSort.insertBefore(search, sort);
					search.querySelector("input").placeholder = "";
				}
			}
		};
		sortSearchResponsive();

		const displayFilters = () => {
			const displayFiltersBtn = document
				.getElementById("display-filters")
				.querySelector("button");
			const filtersBtnText =
				displayFiltersBtn.querySelector("span.subhead-xs");

			if (displayFiltersBtn) {
				const filters = document.querySelector(
					".directory .filters .filter-wrapper"
				);
				const searchSort = document.querySelector(
					".directory .search-sort"
				);
				const resetBtn = document.getElementById("reset-mbl");

				displayFiltersBtn.addEventListener("click", () => {
					if (filters.classList.contains("visible")) {
						filters.classList.remove("visible");
						searchSort.classList.remove("visible");
						resetBtn.classList.remove("visible");
						filtersBtnText.innerText = "Show Filters";
					} else {
						filters.classList.add("visible");
						searchSort.classList.add("visible");
						resetBtn.classList.add("visible");
						filtersBtnText.innerText = "Hide Filters";
					}
				});
			}
		};
		displayFilters();

		const filterProgramYears = new Combobo({
			input: "#filter-by-program",
			list: ".listbox.select-program",
			openClass: "open",
			activeClass: "active",
			selectedClass: "selected",
			useLiveRegion: false,
			multiselect: false,
			noResultsText: null,
			selectionValue: (selecteds) =>
				selecteds.map((s) => s.innerText.trim()).join(" - "),
			// eslint-disable-next-line max-len
			optionValue: "underline", // wrap the matched portion of the option (if applicable) in a span with class "underline"
			filter: "contains", // 'starts-with', 'equals', or funk
		});

		const filterBusinessFunction = new Combobo({
			input: "#filter-by-function",
			list: ".listbox.select-function",
			options: ".option", // qualified within `list`
			openClass: "open",
			activeClass: "active",
			selectedClass: "selected",
			useLiveRegion: false,
			multiselect: false,
			noResultsText: null,
			selectionValue: (selecteds) =>
				selecteds.map((s) => s.innerText.trim()).join(" - "),
			// eslint-disable-next-line max-len
			optionValue: "underline", // wrap the matched portion of the option (if applicable) in a span with class "underline"
			filter: "contains", // 'starts-with', 'equals', or funk
		});

		const filterIndustry = new Combobo({
			input: "#filter-by-industry",
			list: ".listbox.select-industry",
			options: ".option", // qualified within `list`
			openClass: "open",
			activeClass: "active",
			selectedClass: "selected",
			useLiveRegion: false,
			multiselect: false,
			noResultsText: null,
			selectionValue: (selecteds) =>
				selecteds.map((s) => s.innerText.trim()).join(" - "),
			// eslint-disable-next-line max-len
			optionValue: "underline", // wrap the matched portion of the option (if applicable) in a span with class "underline"
			filter: "contains", // 'starts-with', 'equals', or funk
		});
		const sortBy = new Combobo({
			input: "#sort-by",
			list: ".listbox.select-sort",
			options: ".option", // qualified within `list`
			openClass: "open",
			activeClass: "active",
			selectedClass: "selected",
			useLiveRegion: false,
			multiselect: false,
			noResultsText: null,
			selectionValue: (selecteds) =>
				selecteds.map((s) => s.innerText.trim()).join(" - "),
			// eslint-disable-next-line max-len
			optionValue: "underline", // wrap the matched portion of the option (if applicable) in a span with class "underline"
			filter: "contains", // 'starts-with', 'equals', or funk
		});

		const skipTo = new Combobo({
			input: "#skip-to",
			list: ".listbox.select-skip",
			options: ".option", // qualified within `list`
			openClass: "open",
			activeClass: "active",
			selectedClass: "selected",
			useLiveRegion: false,
			multiselect: false,
			noResultsText: null,
			selectionValue: (selecteds) =>
				selecteds.map((s) => s.innerText.trim()).join(" - "),
			// eslint-disable-next-line max-len
			optionValue: "underline", // wrap the matched portion of the option (if applicable) in a span with class "underline"
			filter: "contains", // 'starts-with', 'equals', or funk
		});

		const resetAll = () => {
			const resetElemMbl = document.getElementById("reset-mbl");
			const resetBtnMbl = resetElemMbl.querySelector("button");
			const resetElemDsktp = document.getElementById("reset-dsktp");
			const resetBtnDsktp = resetElemDsktp.querySelector("button");
			const searchField = document.getElementById("search");

			const resetEverything = () => {
				filterProgramYears.reset();
				filterBusinessFunction.reset();
				filterIndustry.reset();
				sortBy.reset();
				skipTo.reset();
				searchField.value = "";
			};

			resetBtnMbl.addEventListener("click", resetEverything);
			resetBtnDsktp.addEventListener("click", resetEverything);
		};
		resetAll();
		const filterBtns= document.querySelectorAll('.trigger-search-btn');
		filterBtns.forEach((filterBtn) => {
			filterBtn.addEventListener("click", (e) => {
				e.preventDefault();
				
				//filterUsers();
					});
		});
		let userForm = document.querySelector('#search-sort');
		let serialize = form => {
			let requestArray = [];
			form.querySelectorAll('[name]').forEach(elem => {		
					if (elem.value !== '')
						requestArray.push(
							elem.name + '=' + encodeURIComponent(elem.value)
						);
			});
			if (requestArray.length > 0) return requestArray.join('&');
			else return false;
		};
		// sortBy.addEventListener( "change", e=> {
	    //    console.log(e);
	  
		//   } );
		
	},
};
