/** import external dependencies */
import $ from "jquery/dist/jquery.js";
import "waypoints/lib/jquery.waypoints.min";

/** import local dependencies */
import Router from "./util/Router";
import common from "./routes/common";
import home from './routes/front-page'; // Login Page
import pageTemplateTemplateHome from "./routes/home";
import pageTemplateTemplateDirectory from "./routes/directory";
//import pageTemplateTemplateProfile from "./routes/profile";
import pageTemplateTemplateUserProfile from "./routes/user-profile";
import pageTemplateTemplateHelpContact from "./routes/help-contact";
import pageTemplateTemplateBonus from "./routes/bonus";

/** import components */
import mobileMenu from "./components/mobileMenu";
import menu from "./components/menu";

/**
 * Populate Router instance with DOM routes
 * @type {Router} routes - An instance of our router
 */
const routes = new Router({
	/** All pages */
	common,
	mobileMenu,
	menu,
	home,
	/** Home page */
	pageTemplateTemplateHome,
	pageTemplateTemplateDirectory,
	//pageTemplateTemplateProfile, //Not sure which page this is supposed to be for. Directory or Profile Edit?
	pageTemplateTemplateUserProfile, //Profile Edit Page
	pageTemplateTemplateHelpContact,
	pageTemplateTemplateBonus,
});

/** Load Events */
jQuery(document).ready(() => routes.loadEvents());
