const menuFunctions = () => {
	const profileDropdownMenu = document.getElementById("profile-menu");
	const profileDropdownButton = document.getElementById("profile-dropdown");

	const profileDropdown = () => {
		const dropdownLinks = profileDropdownMenu.querySelectorAll(
			"a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type='text']:not([disabled]), input[type='radio']:not([disabled]), input[type='checkbox']:not([disabled]), input[type='submit']:not([disabled]), select:not([disabled])"
		);
		const lastDropdownLink = dropdownLinks[dropdownLinks.length - 1];

		// const firstBodyLink = bodyLinks[0];
		const main = document.querySelector("main");
		const footer = document.querySelector("footer");
		const mainLinks = main.querySelectorAll(
			"a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type='text']:not([disabled]), input[type='radio']:not([disabled]), input[type='checkbox']:not([disabled]), input[type='submit']:not([disabled]), select:not([disabled])"
		);
		const firstMainLink = mainLinks[0];
		const footerLinks = footer.querySelectorAll(
			"a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type='text']:not([disabled]), input[type='radio']:not([disabled]), input[type='checkbox']:not([disabled]), input[type='submit']:not([disabled]), select:not([disabled])"
		);
		const firstFooterLink = footerLinks[0];

		const displayDropdown = () => {
			profileDropdownButton.classList.add("hovered");
			profileDropdownMenu.classList.add("hovered");
		};
		const hideDropdown = () => {
			profileDropdownButton.classList.remove("hovered");
			profileDropdownMenu.classList.remove("hovered");
		};

		const accessibleDropdown = (e) => {
			// display dropdown when keyboard user hits enter
			if (e.keyCode === 13) {
				profileDropdownButton.classList.toggle("hovered");
				profileDropdownMenu.classList.toggle("hovered");
			} else if (e.shiftKey && e.keyCode === 9) {
				// hide dropdown if user is tabbing backwards from button
				profileDropdownButton.classList.remove("hovered");
				profileDropdownMenu.classList.remove("hovered");
			}
		};

		// hide dropdown + focus on outside element when tabbing out of menu
		lastDropdownLink.addEventListener("keydown", (e) => {
			// if focused on last link and going backwards, do nothing
			if (e.shiftKey && e.keyCode === 9) {
				return null;
			} else if (e.keyCode === 9) {
				// otherwise, exit dropdown and focus on first focusable link
				e.preventDefault();
				hideDropdown();
				if (firstMainLink) {
					firstMainLink.focus();
				} else if (firstFooterLink) {
					firstFooterLink.focus();
				}
			}
		});

		profileDropdownButton.addEventListener("mouseenter", displayDropdown);
		profileDropdownMenu.addEventListener("mouseenter", displayDropdown);
		profileDropdownButton.addEventListener("mouseleave", hideDropdown);
		profileDropdownMenu.addEventListener("mouseleave", hideDropdown);
		profileDropdownButton.addEventListener("keydown", accessibleDropdown);
	};
	if(profileDropdownMenu !==null&& profileDropdownButton !==null){
		profileDropdown();
	}
	
};
menuFunctions();
