import SmoothScroll from "smooth-scroll";

export default {
	init() {
        const navList = document.querySelector('#navigate ul');
        const passwordLink = navList.querySelector('#logout-button').parentElement;
        const formHeaders = document.querySelectorAll('form h3');
        // Temporarily remove password link
        navList.removeChild(passwordLink);

        // Let's cycle through each header and create the anchors
        formHeaders.forEach(header => {
            // Set up the ID on the titles
            const title = header.innerHTML;
            let titleId = title.replace(' ', '-');
            // Conditional in case the header already has an ID on it
            if (!header.id){
                header.id = titleId;
            } else {
                titleId = header.id;
            }
            // Cool, now let's create the new anchor link;
            const newLi = document.createElement('li');
            newLi.innerHTML = `<a href="#${titleId}">${title}</a>`;
            navList.appendChild(newLi);
        })
        // Move Password link to bottom
        navList.appendChild(passwordLink);
    },
	finalize() {
		// JavaScript to be fired on all pages, after page specific JS is fired

		let laptopLg = window.matchMedia("(min-width: 1050px)");

		const stickySidenav = () => {
			const sidenav = document.querySelector(".profile .navigate");
			const sidenavTop = sidenav.offsetTop - 173;
			const stickyOnScroll = () => {
				if (laptopLg.matches) {
					if (window.scrollY >= sidenavTop) {
						sidenav.classList.add("fixed");
					} else {
						sidenav.classList.remove("fixed");
					}
				}
			};

			window.addEventListener("scroll", stickyOnScroll);
		};
		stickySidenav();

        //Commenting this out, since it affects the User Profile Edit Page instead of just the directory.
		const showUserProfileImage = () => {
			const imageDiv = document.querySelector("#user-profile-image");
			if(typeof USERPROFILE.temp_profile_image.scalar !== "undefined"){
				imageDiv.innerHTML = USERPROFILE.temp_profile_image.scalar;
			} else {
                imageDiv.innerHTML = ""; //Display nothing
            } /*else if(typeof USERPROFILE.profile_image.scalar !== "undefined"){
                imageDiv.innerHTML = USERPROFILE.profile_image.scalar;
            } */
		};
		showUserProfileImage();

		const smoothScrollAnchors = () => {
			const scrollAnchors = new SmoothScroll('a[href*="#"]', {
				speed: 600,
				offset: 150,
			});
		};
		smoothScrollAnchors();
	},
};
