import SmoothScroll from "smooth-scroll";

export default {
	init() {},
	finalize() {
		// JavaScript to be fired on all pages, after page specific JS is fired
		const smoothScrollAnchors = () => {
			const scrollAnchors = new SmoothScroll('a[href*="#"]', {
				speed: 600,
				offset: 150,
			});
		};
		smoothScrollAnchors();
	},
};
