// import SmoothScroll from "smooth-scroll";
/** import external dependencies */
// import 'van11y-accessible-tab-panel-aria/dist/van11y-accessible-tab-panel-aria.min.js';
import 'van11y-accessible-modal-window-aria/dist/van11y-accessible-modal-window-aria.min.js'

export default {
	init() { },
	finalize() {
		// Quick fix - watch body ducment for no-scroll - apply to HTML if set
		const body = document.body;
		const html = document.querySelector('html');
		setInterval(() => {
			if (body.classList.contains('no-scroll') && !html.classList.contains('no-scroll')){
				html.classList.add('no-scroll');
			} else if (!body.classList.contains('no-scroll') && html.classList.contains('no-scroll')){
				html.classList.remove('no-scroll');
			}
		}, 250);
	},
};
